import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import useApexWeapons from "@/game-apex/useApexWeapons.jsx";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ApexLegendStat = ({ season, mode, profileId }) => {
  const { t } = useTranslation();
  const { weapons: myWeapons } = useApexWeapons({
    season,
    mode,
    profileId,
  });
  const state = useSnapshot(readState);
  const weapons = state.apex.meta?.weapons;

  if (!myWeapons?.length) return null;
  return (
    <ListItemsTruncated
      title={t("common:weapons", "Weapons")}
      list={myWeapons}
      itemLeftContent={(item) => {
        const { weaponId, headshotPercentage: hsPercent } = item;
        const weaponInfo = weapons?.[weaponId];
        const weaponImgUrl = staticMediaURLs.getWeaponImage(weaponId);
        const weaponName = weaponInfo?.name || "-";

        return (
          <>
            <img
              className="legend-icon"
              src={weaponImgUrl}
              width="72"
              height="36"
              alt={weaponName}
            />
            <div>
              <div className="type-callout--bold name">
                <span>{weaponName}</span>
              </div>
              <div className="type-caption">
                <span className="sub-stat shade1">
                  {t(
                    "common:stats.valueHsPercent",
                    "{{hsPercent, percent}} HS",
                    {
                      hsPercent,
                    },
                  )}
                </span>
              </div>
            </div>
          </>
        );
      }}
      itemRightContent={(item) => {
        const { kills, accuracy } = item;
        return (
          <>
            <div className="type-caption--bold">
              <span>
                {t("common:stats.killsCount", "{{count, number}} Kills", {
                  count: kills,
                })}
              </span>
            </div>
            <div className="type-caption sub-stat shade2">
              <span>
                {t("apex:stats.accuracy", "{{accuracy, percent}} Accuracy", {
                  accuracy,
                })}
              </span>
            </div>
          </>
        );
      }}
    />
  );
};

export default React.memo(ApexLegendStat);
