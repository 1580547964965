import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import useApexLegends from "@/game-apex/use-apex-legends.mjs";
import useParams from "@/game-apex/utils/use-params.mjs";
import type { Cell, Column } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const LegendIcon = styled("img")`
  width: var(--sp-9);
  height: var(--sp-9);
  margin-right: var(--sp-6);
  border-radius: 50%;
`;

const ProfileLegends = () => {
  const { t } = useTranslation();

  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();
  const { season: seasonParam, mode: modeParam } = useParams(searchParams);
  const state = useSnapshot(readState);

  const legends = state.apex.meta?.legends;

  const { legends: aggLegends } = useApexLegends({
    season: seasonParam,
    mode: modeParam,
    profileId,
  });

  const cols: Column[] = useMemo(() => {
    return [
      {
        display: t("common:legend", "Legend"),
        align: "left",
        primary: true,
      },
      {
        display: t("common:kills", "Kills"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:killsPerMatch", "Kills/Match"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:wins", "Wins"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:pickRate", "Pick Rate"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:dmgPerMatch", "Dmg/Match"),
        align: "right",
        isStat: true,
      },
      {
        display: t("common:matches", "Matches"),
        align: "right",
        isStat: true,
      },
    ];
  }, [t]);

  const rows: Cell[][] = useMemo(() => {
    return aggLegends.map((legend) => {
      const { name: legendName } = legends[legend.championId] || {};
      const legendImgUrl = staticMediaURLs.getLegendImage(legend.championId);
      return [
        {
          display: (
            <div className="flex align-center gap-1">
              <LegendIcon
                src={legendImgUrl}
                className="champion-image"
                alt={legendName}
                width="36"
                height="36"
              />
              <span>{legendName}</span>
            </div>
          ),
          value: legendName,
        },
        {
          display: getLocaleString(legend.kills),
          value: legend.kills,
        },
        {
          display:
            typeof legend.killsPerMatch === "number"
              ? getLocaleString(legend.killsPerMatch, {
                  maximumFractionDigits: 1,
                })
              : "-",
          value: legend.killsPerMatch || 0,
        },
        {
          display: getLocaleString(legend.wins),
          value: legend.wins,
        },
        {
          display:
            typeof legend.pickRate === "number"
              ? t("common:percent", "{{percent, percent}}", {
                  percent: legend.pickRate,
                })
              : "-",
          value: legend.pickRate,
        },
        {
          display:
            legend.damagePerMatch >= 0
              ? getLocaleString(legend.damagePerMatch, {
                  maximumFractionDigits: 1,
                })
              : "-",
          value: legend.damagePerMatch,
        },
        {
          display:
            typeof legend.matchCount === "number"
              ? getLocaleString(legend.matchCount)
              : "-",
          value: legend.matchCount || 0,
        },
      ];
    });
  }, [legends, aggLegends, t]);

  return (
    <DataTable
      cols={cols}
      rows={rows}
      indexCol
      sortCol={1}
      sortColTiebreak={2}
    />
  );
};

export default ProfileLegends;
