import React, { useMemo } from "react";
import { Select } from "clutch/src/Select/Select.jsx";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import {
  GAME_MODE_KEYS,
  GAME_MODES,
  LIFETIME_SEASON,
} from "@/game-apex/constants.mjs";
import SearchParamsEnum from "@/game-apex/constants/search-params.mjs";
import type { ApexSeason } from "@/game-apex/models/seasons.mjs";
import { getCurrentSeason } from "@/game-apex/utils.mjs";
import { getSplitRanksByPlayerSeason } from "@/game-apex/utils/get-split-ranks-by-player-season.mjs";
import useParams from "@/game-apex/utils/use-params.mjs";
import ApexUnranked from "@/inline-assets/apex-unranked.svg";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";
import useUpdateSearchParam from "@/util/use-update-search-param.mjs";

type ReadOnlySeason = DeepReadonly<ApexSeason>;

export function Modes({
  hideNoLegendStats,
  hideNoWepStats,
}: {
  hideNoLegendStats: boolean;
  hideNoWepStats: boolean;
}) {
  const { searchParams } = useRoute();
  const options = useMemo(() => {
    return GAME_MODE_KEYS.filter(
      (m) =>
        !GAME_MODES[m].hidden &&
        (!hideNoLegendStats || !GAME_MODES[m].noLegendStats) &&
        (!hideNoWepStats || !GAME_MODES[m].noWepStats),
    ).map((mode) => {
      const modeObj = GAME_MODES[mode];
      return {
        value: modeObj.key,
        text:
          (hideNoLegendStats || hideNoWepStats) && modeObj.tBr
            ? modeObj.tBr
            : modeObj.t,
      };
    });
  }, [hideNoLegendStats, hideNoWepStats]);

  const updateSearchParam = useUpdateSearchParam(SearchParamsEnum.Mode);
  const { mode } = useParams(searchParams);

  return (
    <Select options={options} onChange={updateSearchParam} selected={mode} />
  );
}

function SeasonRankIcon({
  profileId,
  mode,
  season,
}: {
  profileId: string;
  mode: string;
  season: ReadOnlySeason;
}) {
  const state = useSnapshot(readState);
  const playerStats = state.apex.playerStats[profileId];
  const playerSeasonStats = playerStats?.[season.apexSeasonNumber];

  const playerSplitRanks = getSplitRanksByPlayerSeason(
    playerSeasonStats,
    mode,
    season.apexId,
    season,
  );
  const FirstSplitIcon = playerSplitRanks?.firstSplit?.icon || ApexUnranked;
  // const SecondSplitIcon = playerSplitRanks?.secondSplit?.icon || ApexUnranked;

  return (
    <div className="flex">
      <FirstSplitIcon style={{ marginRight: "var(--sp-2)" }} />
      {/*<SecondSplitIcon />*/}
    </div>
  );
}

export function Seasons() {
  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();
  const state = useSnapshot(readState);
  const seasons = state.apex.meta.seasons;

  const { season, mode } = useParams(searchParams);
  const options = useMemo(() => {
    const currentSeason = getCurrentSeason(seasons) as ReadOnlySeason;
    return (Object.values(seasons) as ApexSeason[])
      .sort((s1, s2) => {
        if (s2.apexSeasonNumber === LIFETIME_SEASON.apexSeasonNumber) return 1;
        if (s1.apexSeasonNumber === LIFETIME_SEASON.apexSeasonNumber) return -1;
        return parseInt(s2.apexSeasonNumber) - parseInt(s1.apexSeasonNumber);
      })
      .map((season) => {
        const isLifetime =
          season.apexSeasonNumber === LIFETIME_SEASON.apexSeasonNumber;
        return {
          text: isLifetime
            ? (["common:lifetime", "Lifetime"] as Translation)
            : ([
                "apex:season",
                "Season {{number}}",
                { number: season.apexSeasonNumber },
              ] as Translation),
          value: season.apexSeasonNumber,
          icon: (
            <SeasonRankIcon
              profileId={profileId}
              season={isLifetime ? currentSeason : season}
              mode={mode}
            />
          ),
        };
      });
  }, [seasons, mode, profileId]);

  const updateSearchParam = useUpdateSearchParam(SearchParamsEnum.Season);

  return (
    <Select
      options={options}
      onChange={updateSearchParam}
      selected={season}
      hideSelectedIcon
    />
  );
}
