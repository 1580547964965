import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { GAME_MODES } from "@/game-apex/constants.mjs";
import staticMediaURLs from "@/game-apex/static.mjs";
import {
  getLegendFromModelName,
  getPlayerStatsByMatch,
} from "@/game-apex/utils.mjs";
import LiveTile from "@/shared/LiveTile.jsx";
import { MatchTile as SharedMatchTile } from "@/shared/Profile.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ApexLiveTile = ({ profileId }) => {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const legends = state.apex.meta.legends;

  // this is just to trigger rerender
  void state.apex.liveGameTime;

  const match = state.apex.liveGame;
  const loggedInAccountId = state.settings.lastLoggedInIdByGame.apex;
  const { gameStartedAt, gameMode } = match || {};
  const modeObj = GAME_MODES[gameMode];

  const { myImage, myTeam } = useMemo(() => {
    if (!match?.playerMatchStats) return {};
    const myPlayer = getPlayerStatsByMatch(match, profileId);
    if (!myPlayer) return {};
    let myImage;
    const myTeam = match.playerMatchStats
      .filter((p) => p.team.apexId === myPlayer.team.apexId)
      .map((p) => {
        const legend = getLegendFromModelName(legends, p.modelName);
        const isMe = p.platformId === myPlayer.platformId;
        const image = staticMediaURLs.getLegendImage(legend?.apexId);
        if (isMe) myImage = image;
        return {
          id: p.platformId,
          ImgComponent: (
            <img src={image} className={isMe ? "user" : "teammate"} />
          ),
        };
      });
    return {
      myImage,
      myTeam,
    };
  }, [legends, match, profileId]);

  return match && profileId === loggedInAccountId ? (
    <SharedMatchTile height={128} match={match} isLive>
      <LiveTile
        title={t("common:liveGame", "Live Game")}
        queueType={modeObj ? t(...modeObj.t) : null}
        startTime={gameStartedAt * 1000}
        myTeam={myTeam}
        image={myImage}
      />
    </SharedMatchTile>
  ) : null;
};

export default ApexLiveTile;
