import React, { useMemo } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import { GAME_MODES, MatchTileTabsEnum } from "@/game-apex/constants.mjs";
import type { ApexMatch } from "@/game-apex/models/match.mjs";
import ApexStatic from "@/game-apex/static.mjs";
import { getMatchResultData } from "@/game-apex/utils.mjs";
import getHistogram from "@/game-apex/utils/get-histogram.mjs";
import getDataTablePills from "@/shared/get-data-table-pills.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber, toFixedNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocaleString } from "@/util/i18n-helper.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-apex/components/MatchTileScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-apex/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Weapons]: () =>
    import("@/game-apex/components/MatchTileWeapons.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-apex/components/MatchTileHits.jsx"),
};
export const PROFILE_MATCHTILE_TABS = [
  {
    name: ["tft:matchtabs.scoreboard", "Scoreboard"] as Translation,
    key: MatchTileTabsEnum.Scoreboard,
  },
  {
    name: ["common:navigation.statistics", "Statistics"] as Translation,
    key: MatchTileTabsEnum.Statistics,
  },
  {
    name: ["eft:navigation.weaponKills", "Weapon Kills"] as Translation,
    shortName: ["common:stats.kills", "Kills"] as Translation,
    key: MatchTileTabsEnum.Weapons,
  },
  {
    name: ["val:stats.hits", "Hits"] as Translation,
    key: MatchTileTabsEnum.Hits,
  },
];

function MatchTile({
  profileId,
  match,
}: {
  profileId: string;
  match: DeepReadonly<ApexMatch>;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);

  const weapons = state.apex.meta.weapons;
  const matches = state.apex.matches as { [gameId: string]: ApexMatch };
  const gameId = match.apexId;
  const gameMode = GAME_MODES[match.gameMode];

  const player = useMemo(
    () => match.playerMatchStats.find((i) => i.platformId === profileId),
    [match.playerMatchStats, profileId],
  );

  const { win, label } = useMemo(
    // Todo: Refactor this function entirely,
    // The IsLive boolean doesn't even have a match id equality check to verify its the correct match to show the right label lol
    () => getMatchResultData(t, gameMode, player, false),
    [gameMode, player, t],
  );

  type ApexMatchWeaponStats =
    ApexMatch["playerMatchStats"][0]["playerMatchWeaponStats"][0];

  const playerWeapons: ApexMatchWeaponStats[] = useMemo(
    () =>
      orderArrayBy<ApexMatchWeaponStats, ApexMatchWeaponStats[]>(
        // @ts-ignore
        player?.playerMatchWeaponStats ?? ([] as ApexMatchWeaponStats),
        ["kills", "damageDone"],
        "desc",
      ),
    [player?.playerMatchWeaponStats],
  );

  const histogram = useMemo(() => {
    const result = getHistogram(matches, profileId);
    // Sorting direction doesn't matter, as long as its sorted
    // Usually we don't want to sort this since data table pills fn automatically does this for us already but we need to calculate other props
    for (const key in result) result[key].sort();
    result.accuracy =
      result.hits?.map((hit, idx) => sanitizeNumber(hit / result.shots[idx])) ??
      [];
    result.accuracyHs =
      result.headshots?.map((hs, idx) =>
        sanitizeNumber(
          hs / (Array.isArray(result.hits) ? result.hits[idx] || 1 : 1),
        ),
      ) ?? [];
    return result;
  }, [matches, profileId]);

  const stats = useMemo(() => {
    const kills = sanitizeNumber(player?.kills);
    const damage = sanitizeNumber(player?.damageDone);
    const accuracy = sanitizeNumber(player?.hits / player?.shots);
    const accuracyHs = sanitizeNumber(player?.headshots / player?.hits);
    return [
      {
        top: (
          <span>
            {t("common:stats.killsCount", "{{count, number}} Kills", {
              count: kills,
            })}
          </span>
        ),
        pill: getDataTablePills(kills, histogram.kills),
        bottom: t("common:stats.kills", "Kills"),
      },
      {
        top: getLocaleString(toFixedNumber(damage, 1)),
        pill: getDataTablePills(damage, histogram.damageDone),
        bottom: t("common:stats.damage", "Damage"),
      },
      {
        top: formatToPercent(language, accuracy),
        pill: getDataTablePills(accuracy, histogram.accuracy),
        bottom: t("common:accuracy", "Accuracy"),
      },
      {
        top: formatToPercent(language, accuracyHs),
        pill: getDataTablePills(accuracyHs, histogram.accuracyHs),
        bottom: t("common:stats.hsPercent", "HS%"),
      },
    ];
  }, [player, histogram, language, t]);

  const playerBestWeapon = playerWeapons[0];
  const playerBestWeaponAsset = weapons[playerBestWeapon?.weapon.apexId];

  return (
    <MatchTileExpand
      image={{
        src: ApexStatic.getLegendImage(player?.champion?.apexId),
        alt: player?.champion?.apexId,
      }}
      isWin={win}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={PROFILE_MATCHTILE_TABS}
      tabArgs={{
        match,
        profileId,
      }}
      stats={stats}
      urlWeb={`${globals.location.origin}/apex/match/${profileId}/${match.season?.apexId}/${gameId}`}
      urlCanonical={`/apex/match/${profileId}/${match.season?.apexId}/${gameId}`}
      titles={[
        {
          text: label,
          bold: true,
          color: win ? "var(--turq)" : "var(--red)",
        },
        gameMode?.t && {
          text: t(...gameMode.t),
        },
        player.rankedPoints && {
          text: t("apex:stats.rpWithPoints", "{{rp, number}} RP", {
            rp: player.rankedPoints,
            formatParams: { signDisplay: "always" },
          }),
          color: player.rankedPoints > 0 ? "var(--turq)" : "var(--red)",
        },
        player.survivalTime && {
          text: formatDuration(
            sanitizeNumber(player.survivalTime * 1000),
            "mm:ss",
          ),
        },
      ].filter(Boolean)}
      matchDate={match.gameStartedAt * 1000}
      aside={
        <BestWep
          playerBestWeapon={playerBestWeapon}
          playerBestWeaponAsset={playerBestWeaponAsset}
        />
      }
    />
  );
}

export default MatchTile;

function BestWep({ playerBestWeapon, playerBestWeaponAsset }) {
  const { t } = useTranslation();

  if (!playerBestWeapon) return null;

  return (
    <>
      <img
        src={ApexStatic.getWeaponImage(playerBestWeapon?.weapon.apexId)}
        alt={playerBestWeaponAsset?.name}
        width="96"
        height="48"
        style={{ width: "var(--sp-24)" }}
        data-tip={
          playerBestWeapon
            ? renderToStaticMarkup(
                <>
                  {playerBestWeaponAsset?.name},{" "}
                  {`${t("common:stats.damage", "Damage")}: ${
                    playerBestWeapon.damageDone
                  }`}
                </>,
              )
            : undefined
        }
      />
      <div
        className="color-shade2 type-caption"
        style={{ textAlign: "center", paddingBlockStart: "var(--sp-2)" }}
      >
        {t("common:stats.killsCount", "{{count, number}} Kills", {
          count: sanitizeNumber(playerBestWeapon?.kills),
        })}
      </div>
    </>
  );
}
