import type { DeepReadonly } from "ts-essentials";

import type { GameMode } from "@/game-apex/constants.mjs";
import { GAME_MODES } from "@/game-apex/constants.mjs";
import type { ArenaDivision } from "@/game-apex/constants/constants-divisions.mjs";
import type { ApexPlayerStats } from "@/game-apex/models/player-stats.mjs";
import type { ApexSeason } from "@/game-apex/models/seasons.mjs";
import { getDivision } from "@/game-apex/utils/get-division.mjs";

interface PlayerSeasonRankSplit extends Partial<ArenaDivision> {
  rankedPoint?: number;
  rankedRating?: number;
}

export const getSplitRanksByPlayerSeason = (
  seasonData: Record<string, ApexPlayerStats>,
  gameMode: string,
  season: ApexSeason["apexId"],
  seasonMeta: DeepReadonly<ApexSeason>,
): {
  firstSplit?: PlayerSeasonRankSplit;
  secondSplit?: PlayerSeasonRankSplit;
  apexSeasonNumber?: string;
} => {
  const rankedMode = GAME_MODES[gameMode as GameMode]?.rankedMode as string;
  const currentSeasonData = seasonData?.[rankedMode];

  if (!rankedMode || !currentSeasonData?.matchCount) {
    return {};
  }

  const ranks: {
    firstSplit?: PlayerSeasonRankSplit;
    secondSplit?: PlayerSeasonRankSplit;
    apexSeasonNumber?: string;
  } = { apexSeasonNumber: seasonMeta?.apexSeasonNumber };

  // Note: As far as I can see, ranked data from game doesn't contain firstSplitRankedPoints anymore
  // const hasSplitOccured = seasonMeta?.hasSplitResetOccured;

  // Add rank information for 1st split
  // const firstRankscore = hasSplitOccured
  //   ? currentSeasonData?.firstSplitRankedPoints
  //   : currentSeasonData?.rankedPoints;

  const firstRankscore = currentSeasonData?.rankedPoints;
  const firstRankInfo = getDivision(Number(firstRankscore), rankedMode, season);

  ranks.firstSplit = {
    ...firstRankInfo,
    rankedPoint: firstRankscore,
    rankedRating: firstRankInfo?.rankRating,
  };

  // Add rank information for 2nd split
  // if (hasSplitOccured) {
  //   const secondRankscore = currentSeasonData?.rankedPoints;
  //   const secondRankInfo = getDivision(
  //     Number(secondRankscore),
  //     rankedMode,
  //     season,
  //   );
  //
  //   ranks.secondSplit = {
  //     ...secondRankInfo,
  //     rankedPoint: secondRankscore,
  //     rankedRating: secondRankInfo?.rankRating,
  //   };
  // }

  return ranks;
};
